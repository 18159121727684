import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { SafeHtml } from '@angular/platform-browser';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { takeWhile } from 'rxjs/operators';
import { DocumentCategory } from 'src/app/order/enums/document-category.enum';
import { ModalDefinitions } from 'src/app/order/enums/modal-definitions.enum';
import { DateMask } from 'src/app/order/masks/date-mask';
import { cpfCnpjMask, cpfMask } from 'src/app/order/masks/document-masks';
import { RneMask } from 'src/app/order/masks/rne-mask';
import { Messages } from 'src/app/order/messages/order.messages';
import { NationalityService } from 'src/app/order/services/external/nationality/nationality.service';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { SerproService } from 'src/app/order/services/external/serpro/serpro.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { CpfCnpjValidator } from 'src/app/order/validators/cpf-cnpj-validator';
import { CpfValidator } from 'src/app/order/validators/cpf-validator';
import { dateValidator } from 'src/app/order/validators/date-validator';
import { NameSurnameValidator } from 'src/app/order/validators/name-surname-validator';
import { PercentValidator } from 'src/app/order/validators/percent.validator';
import { RequiredIfValidator } from 'src/app/order/validators/required-if-validator';
import { InputType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { FdSelectConfig } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { LegalRepresentative, PartnerDataModel, Proposal } from 'src/app/shared/models/proposal';
import { ResponseClientSerproModel } from 'src/app/shared/models/response/response-client-serpro-situation.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationModel, SerproValidationStrategyEnum } from 'src/app/start/models/configuration/configuration.model';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../form-step';
import { PartnerSerproModel } from './models/partner-serpro.model';
import {
  LegalRepresentativeDTO,
  PartnerV2ValidationDTO,
  SerproV2PartnerValidationModel,
} from './models/serpro-v2-partner-validation.model';
import { CNPJ_REGEX, CPF_REGEX } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-partner-data',
  templateUrl: './partner-data.component.html',
  styleUrls: ['./partner-data.component.scss'],
})
export class PartnerDataComponent extends FormStep implements OnInit {
  get addPartnerButtonHTML() {
    return this.renderPlusIcon() + ' <span>adicionar proprietário</span>';
  }

  get removePartnerButtonHTML() {
    return this.renderCloseIcon() + ' <span>remover proprietário</span>';
  }

  get restrictedCnaeSelected() {
    return this.proposal && this.proposal.offerDataDetails && this.proposal.offerDataDetails.restrictedCnaeSelected;
  }

  private get partnerFormsArray(): FormArray {
    return this.formControls.partnerForms as FormArray;
  }

  public get partnerFormsControls() {
    return this.partnerFormsArray.controls;
  }

  get complianceDataEnabled() {
    return this.configuration && this.configuration.ableComplianceData;
  }

  get motherDataEnabled() {
    return this.configuration && this.configuration.ableMotherName;
  }

  public STEP_NUMBER = 0;
  public successDocumentCall: boolean;
  public loadedPartners: boolean;
  public proposal: Proposal;
  public constitutionFormCode: string;
  public configuration: ConfigurationModel;
  public listDate = new Array<string>();
  public isBirthValid = true;
  public isSerproError = false;
  public showLegalRepresentativeFields = false;
  public isSerproPersonalInfoPageStrategy: boolean;
  public nationalityList = [];
  public documentCategory: string;
  public documentCategoryContextDefault = DocumentCategory.CPF;

  @ViewChild('tooltip') public tooltip: MatTooltip;

  public SOURCE_FIXED_DOCUMENT: string;
  public CURRENT_DOCUMENT_CALL_TRY = 0;

  constructor(
    private formBuilder: FormBuilder,
    private serproService: SerproService,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    private nationalityService: NationalityService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.getNationalityList();
    const serproValidationStrategy = this.configurationService.getConfigurationFromSessionStorage().serproValidationStrategy;
    this.isSerproPersonalInfoPageStrategy = serproValidationStrategy === SerproValidationStrategyEnum.PERSONAL_INFO_PAGE;
  }

  public getFormControlByIndex(index: number) {
    const formArray = this.partnerFormsArray;
    return (formArray.controls[index] as FormGroup)?.controls;
  }

  public addPartnerForm(partnerData: Partial<PartnerDataModel> = {}) {
    if (this.fieldsArray.length > 0 && !this.isFormGroupValid()) {
      return;
    }

    this.addPartnerFormInternal(partnerData);
  }

  public shouldShowRemoveButton(index: number): boolean {
    return this.fieldsArray.length > 1 && index < this.fieldsArray.length - 1;
  }

  public removePartnerForm(index: number) {
    this.partnerFormsArray.removeAt(index);
    this.fieldsArray.splice(index, 1);
  }

  public getTotalAmountOfPorcent() {
    let amount = 0;
    if (this.partnerFormsArray && this.partnerFormsArray.controls) {
      const formGroupList: FormGroup[] | AbstractControl[] = this.partnerFormsArray.controls;

      formGroupList.forEach((formGroup) => {
        if (formGroup instanceof FormGroup) {
          const controls = formGroup.controls;
          if (controls && controls.companyParticipation) {
            amount += Number(controls.companyParticipation.value);
          }
        }
      });
    }
    return amount;
  }

  public handleResponseSerpro(response: ResponseClientSerproModel, nextAction: () => any): void {
    if (!response) {
      this.dialogService.openDialog(Messages.SERPRO_VALIDATION_ERROR);
      return;
    }
    const errorList = [];

    response?.inconsistencias?.forEach((serproResponse) => {
      errorList.push(serproResponse);
    });

    if (errorList?.length === 0) {
      this.successDocumentCall = true;
      nextAction();
    } else {
      const ERROR = new Messages('Atenção', 'Não foi possível validar os dados na SERPRO.');
      let errorMsg = '';

      errorList.forEach((error, index) => {
        errorMsg += `${index + 1} - ${error.descricao}.\n\n`;
      });
      this.dialogService.openErrorDialog(ERROR, errorMsg, null, null, null, ModalDefinitions.LARGE_MODAL_WIDTH);
      return;
    }
  }

  public setAndCheckEmpty(control: AbstractControl, data: string) {
    if (data) {
      control.setValue(data);
      control.disable();
    } else {
      control.enable();
    }
  }

  public checkSerproCall() {
    if (this.configuration?.serproValidationStrategy === SerproValidationStrategyEnum.PERSONAL_INFO_PAGE) {
      this.callSerpro();
    } else {
      this.next();
    }
  }

  public callSerpro() {
    if (!this.isFormGroupValid()) {
      return;
    }

    const totalAmount = this.getTotalAmountOfPorcent();
    const maxPorcentValid = 100;

    if (totalAmount > maxPorcentValid) {
      this.dialogService.openDialog(Messages.MAX_PORCENT_LIMIT_REACHED);
      return;
    }

    let serproV2PartnerValidationModel: SerproV2PartnerValidationModel;

    if (this.partnerFormsArray && this.partnerFormsArray.controls) {
      const formGroupList: FormGroup[] | AbstractControl[] = this.partnerFormsArray.controls;
      serproV2PartnerValidationModel = this.createPartnerValidationModel(formGroupList);
    }

    this.serproService
      .validatePartnerV2(serproV2PartnerValidationModel)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          this.handleResponseSerpro(response, () => this.next());
        },
        (error) => {
          this.handleError(error, 'Serpro');
        }
      );
  }

  getNationalityList(): void {
    this.nationalityService.getNationalityList().subscribe(
      (response) => {
        if (response !== null) {
          this.nationalityList = response;
        }
      },
      () => {
        this.dialogService.openErrorDialog(
          Messages.FAILED_TO_GET_BANK_LIST,
          'Api Config - Nacionalidade',
          () => this.wizardService.previousStep(),
          null,
          'error'
        );
      }
    );
  }

  public handleError(error: any, serviceKey: string) {
    if (!error.status) {
      this.dialogService.openDialog(Messages.GENERAL_ERROR);
      console.error(`[${serviceKey}] - Unspecified error when querying CPF/CNPJ`);
    } else if (error.status === 400) {
      if (error.error.message) {
        this.dialogService.openDialog(new Messages('Atenção', error.error.message), this.redirectToHome.bind(this));
      } else {
        this.dialogService.openDialog(Messages.CPF_CNPJ_NOT_AUTHORIZED, this.redirectToHome.bind(this));
      }
    } else if (error.status === 404) {
      this.dialogService.openDialog(Messages.INVALID_CPF_CNPJ);
      console.error(`[${serviceKey}] - CPF/CNPJ not found`);
    } else {
      this.dialogService.openDialog(Messages.GENERAL_ERROR);
    }
  }

  public redirectToHome() {
    document.location.href = '/';
  }

  public getIndex() {
    let index = 0;
    if (this.fieldsArray.length) {
      index = this.fieldsArray.length - 1;
    }

    return index;
  }

  public cpfCnpjChange(index: number, document: string): void {
    const cpfCnpjForm = this.getCpfCnpjForm(index);
    this.processAllValidation(index, document);

    if (this.isCpfOrCnpj(document) && cpfCnpjForm.valid) {
      this.SOURCE_FIXED_DOCUMENT = document;
    }
  }

  public processAllValidation(index: number, document: string): void {
    if (!document) {
      return;
    }

    if (this.isCNPJ(document)) {
      this.processAllValidationForCnpj(index);
    } else {
      this.processAllValidationForCpf(index);
    }
  }

  protected proposalGetCallback(): void {}

  protected async persistData(): Promise<boolean> {
    const totalAmount = this.getTotalAmountOfPorcent();
    const maxPorcentValid = 100;

    if (totalAmount > maxPorcentValid) {
      this.dialogService.openDialog(Messages.MAX_PORCENT_LIMIT_REACHED);
      return Promise.resolve(false);
    }

    const partnerDataModel = [];

    this.partnerFormsArray.controls.forEach((control, index) => {
      const partner = (control as FormGroup).getRawValue();
      const documentCategoryContext = this.getDocumentCategoryContext(index);
      this.persistDataForCpf(partner, documentCategoryContext);
      this.persistDataForCnpj(partner, documentCategoryContext);
      this.cleanProperties(partner);

      partnerDataModel.push(partner);
    });

    const cpfList = partnerDataModel.map((x) => x.cpfCnpj);
    const hasDuplicatedCpf = cpfList.length !== new Set(cpfList).size;

    if (hasDuplicatedCpf) {
      this.dialogService.openDialog(Messages.DUPLICATED_PARTNER_CPF);
      return Promise.resolve(false);
    }

    return this.dataStore.updateProposal({ partnerDataModel });
  }

  private cleanProperties(partner: any) {
    const propertiesListToRemove = [
      'legalRepresentativeEnable',
      'legalRepresentativeRneEnabled',
      'legalRepresentativeRne',
      'legalRepresentativeCpf',
      'legalRepresentativeName',
      'legalRepresentativeBirthDate',
      'legalRepresentativeNationality',
      'legalRepresentativePoliticalExposition',
      'legalRepresentativePepName',
      'legalRepresentativePepRole',
      'legalRepresentativePepGovernmentOrganization',
    ];
    this.removeProperty(partner, propertiesListToRemove);
  }

  private removeProperty(partner: any, properties: any) {
    properties.forEach((property) => Reflect.deleteProperty(partner, property));
  }

  protected updateFields(proposal: Proposal): void {
    const partnerData = proposal.partnerDataModel;

    this.configurationService.getConfiguration().subscribe((configuration) => {
      if (partnerData && partnerData.length > 0) {
        this.loadedPartners = partnerData.find((x) => x.loadedPartners) ? true : false;
        partnerData.forEach((data) => {
          this.addPartnerFormInternal(data);
        });
      } else if (configuration?.serproValidationStrategy === SerproValidationStrategyEnum.PERSONAL_INFO_PAGE) {
        this.serproService
          .getCnpjInformationV2(this.proposal?.registrationDataPersonalInfo?.cpfCnpj)
          .pipe(takeWhile(() => this.alive))
          .subscribe(
            (response) => {
              const partners = response.socios;
              if (partners?.length > 0) {
                partners.forEach((partnerResponseSerpro) => {
                  this.createPartnerDataModelForCPF(partnerResponseSerpro);
                  this.createPartnerDataModelForCNPJ(partnerResponseSerpro);
                });
              } else {
                this.addPartnerFormInternal();
              }
            },
            (error) => this.handleError(error, 'Serpro')
          );
      } else {
        this.addPartnerFormInternal();
      }
    });
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      partnerForms: this.formBuilder.array([]),
    });
  }

  protected createFields(): FdFieldConfigs {
    return {
      cpfCnpj: {
        label: 'CPF/CNPJ',
        controlName: 'cpfCnpj',
        type: InputType.TEL,
        mask: cpfCnpjMask,
        isNumber: true,
        maskCharsReplace: /[.\/ -]/g,
        messages: {
          required: 'Informe o CPF/CNPJ',
          invalidCnpj: 'CNPJ inválido',
          invalidCpf: 'CPF inválido',
        },
      },
      name: {
        label: 'Nome',
        controlName: 'name',
        messages: {
          required: 'Informe o nome e sobrenome',
          invalid: 'Informe o nome e sobrenome',
        },
      },
      birthDate: {
        label: 'Data de nascimento',
        controlName: 'birthDate',
        maskCharsReplace: /[.\/ -]/g,
        mask: DateMask,
        messages: {
          required: 'Informe a data de nascimento',
          invalid: 'Data inválida',
        },
      },
      motherName: {
        label: 'Nome da mãe',
        controlName: 'motherName',
        messages: {
          required: 'Informe o nome completo da mãe',
          invalid: 'Informe o nome e sobrenome',
        },
      },
      companyParticipation: {
        label: 'Participação na empresa em %',
        controlName: 'companyParticipation',
        customOptions: { prefix: '', suffix: ' %', thousands: '.', decimal: ',', align: 'left', min: 0, max: 100 },
        messages: {
          valueZero: 'Informe a participação na empresa',
        },
      },
      politicalExposition: {
        controlName: 'politicalExposition',
        items: [],
        messages: {
          required: 'Informe se a pessoa é politicamente exposta',
        },
      },
      nationality: {
        label: 'Nacionalidade',
        items: [],
        controlName: 'nationality',
        searchPlaceholder: 'Buscar',
        searchable: true,
        required: true,
        messages: {
          required: 'Informe a nacionalidade',
          invalid: 'Nacionalidade inválida',
          pattern: 'Nacionalidade inválida',
        },
      },
      rne: {
        label: 'Registro Nacional de Estrangeiro (RNE)',
        controlName: 'rne',
        mask: RneMask,
        maskCharsReplace: /[-]/g,
        messages: {
          required: 'Informe o RNE',
        },
      },
      rneEnabled: {
        controlName: 'rneEnabled',
      },
      pepName: {
        label: 'Nome',
        controlName: 'pepName',
        messages: {
          required: 'Informe o nome',
        },
      },
      pepRole: {
        label: 'Cargo',
        controlName: 'pepRole',
        messages: {
          required: 'Informe o cargo',
        },
      },
      pepGovernmentOrganization: {
        label: 'Órgão do Governo',
        controlName: 'pepGovernmentOrganization',
        messages: {
          required: 'Informe o órgão do governo',
        },
      },
      socialReason: {
        label: 'Razão Social',
        controlName: 'socialReason',
        maxLength: 200,
        messages: {
          required: 'Informe a razão social',
          invalid: 'razão social inválida',
        },
      },
      openDate: {
        label: 'Data de fundação',
        mask: DateMask,
        controlName: 'openDate',
        maskCharsReplace: /[/\//]/g,
        messages: {
          required: 'Informe a data de fundação',
          invalid: 'Data inválida',
        },
      },
      legalRepresentativeEnable: {
        controlName: 'legalRepresentativeEnable',
      },
      legalRepresentativeName: {
        label: 'Nome',
        controlName: 'legalRepresentativeName',
        messages: {
          required: 'Informe o nome e sobrenome',
          invalid: 'Informe o nome e sobrenome',
        },
      },
      legalRepresentativeCpf: {
        label: 'CPF',
        controlName: 'legalRepresentativeCpf',
        type: InputType.TEL,
        mask: cpfMask,
        isNumber: true,
        maskCharsReplace: /[.\/ -]/g,
        messages: {
          required: 'Informe o CPF',
          invalidCpf: 'CPF inválido',
        },
      },
      legalRepresentativeBirthDate: {
        label: 'Data de nascimento',
        controlName: 'legalRepresentativeBirthDate',
        maskCharsReplace: /[/\//]/g,
        mask: DateMask,
        messages: {
          required: 'Informe a data de nascimento',
          invalid: 'Data inválida',
        },
      },
      legalRepresentativeNationality: {
        label: 'Nacionalidade',
        items: [],
        controlName: 'legalRepresentativeNationality',
        searchPlaceholder: 'Buscar',
        searchable: true,
        messages: {
          required: 'Informe a nacionalidade',
          invalid: 'Nacionalidade inválida',
          pattern: 'Nacionalidade inválida',
        },
      },
      legalRepresentativePoliticalExposition: {
        controlName: 'legalRepresentativePoliticalExposition',
        items: [],
        messages: {
          required: 'Informe se a pessoa é politicamente exposta',
        },
      },
      legalRepresentativeRne: {
        label: 'Registro Nacional de Estrangeiro (RNE)',
        controlName: 'legalRepresentativeRne',
        mask: RneMask,
        maskCharsReplace: /[-]/g,
        messages: {
          required: 'Informe o RNE',
        },
      },
      legalRepresentativeRneEnabled: {
        controlName: 'legalRepresentativeRneEnabled',
      },
      legalRepresentativePepName: {
        label: 'Nome',
        controlName: 'legalRepresentativePepName',
        messages: {
          required: 'Informe o nome',
        },
      },
      legalRepresentativePepRole: {
        label: 'Cargo',
        controlName: 'legalRepresentativePepRole',
        messages: {
          required: 'Informe o cargo',
        },
      },
      legalRepresentativePepGovernmentOrganization: {
        label: 'Órgão do Governo',
        controlName: 'legalRepresentativePepGovernmentOrganization',
        messages: {
          required: 'Informe o órgão do governo',
        },
      },
      documentCategory: {
        controlName: 'documentCategory',
        context: 'CPF or CNPJ',
      },
      isLegalRepresentativeOptions: {
        controlName: 'isLegalRepresentativeOptions',
      },
    };
  }

  public onChangeLegalRepresentativeOptions(event: boolean, index: number): void {
    this.showLegalRepresentativeFields = event;
    if (event) {
      this.setLegalRepresentativeValidation(index);
    } else {
      this.removeLegalRepresentativeValidators(index);
    }
  }

  private setLegalRepresentativeValidation(index) {
    this.getFormControlByIndex(index).legalRepresentativeCpf.setValidators(Validators.required);
    this.getFormControlByIndex(index).legalRepresentativeName.setValidators([Validators.required, NameSurnameValidator]);
    this.getFormControlByIndex(index).legalRepresentativeBirthDate.setValidators(Validators.required);
    this.getFormControlByIndex(index).legalRepresentativeNationality.setValidators(Validators.required);
    this.getFormControlByIndex(index).legalRepresentativePepGovernmentOrganization.setValidators(Validators.required);
    this.getFormControlByIndex(index).legalRepresentativePepName.setValidators(Validators.required);
    this.getFormControlByIndex(index).legalRepresentativePepRole.setValidators(Validators.required);
    this.getFormControlByIndex(index).legalRepresentativePoliticalExposition.setValidators(Validators.required);
    this.getFormControlByIndex(index).legalRepresentativeRne.setValidators(Validators.required);
  }

  private removeLegalRepresentativeValidators(index: number): void {
    this.clearValuesAndRemoveErrorsLegalRepresentative(index);

    this.getFormControlByIndex(index).legalRepresentativeCpf.clearValidators();
    this.getFormControlByIndex(index).legalRepresentativeName.clearValidators();
    this.getFormControlByIndex(index).legalRepresentativeBirthDate.clearValidators();
    this.getFormControlByIndex(index).legalRepresentativeNationality.clearValidators();
    this.getFormControlByIndex(index).legalRepresentativePepGovernmentOrganization.clearValidators();
    this.getFormControlByIndex(index).legalRepresentativePepName.clearValidators();
    this.getFormControlByIndex(index).legalRepresentativePepRole.clearValidators();
    this.getFormControlByIndex(index).legalRepresentativePoliticalExposition.clearValidators();
    this.getFormControlByIndex(index).legalRepresentativeRne.clearValidators();
    this.getFormControlByIndex(index).legalRepresentativeRneEnabled.clearValidators();
  }

  private clearValuesAndRemoveErrorsLegalRepresentative(index: number): void {
    this.getFormControlByIndex(index).legalRepresentativeCpf.setValue('');
    this.getFormControlByIndex(index).legalRepresentativeName.setValue('');
    this.getFormControlByIndex(index).legalRepresentativeBirthDate.setValue('');
    this.getFormControlByIndex(index).legalRepresentativeNationality.setValue('');
    this.getFormControlByIndex(index).legalRepresentativePepGovernmentOrganization.setValue('');
    this.getFormControlByIndex(index).legalRepresentativePepName.setValue('');
    this.getFormControlByIndex(index).legalRepresentativePepRole.setValue('');
    this.getFormControlByIndex(index).legalRepresentativePoliticalExposition.setValue(null);
    this.getFormControlByIndex(index).legalRepresentativeRne.setValue('');
    this.getFormControlByIndex(index).legalRepresentativeRneEnabled.setValue(false);
    this.setEnableFieldData(false, 'legalRepresentativeRneEnabled');

    this.getFormControlByIndex(index).legalRepresentativeCpf.setErrors(null);
    this.getFormControlByIndex(index).legalRepresentativeName.setErrors(null);
    this.getFormControlByIndex(index).legalRepresentativeBirthDate.setErrors(null);
    this.getFormControlByIndex(index).legalRepresentativeNationality.setErrors(null);
    this.getFormControlByIndex(index).legalRepresentativePepGovernmentOrganization.setErrors(null);
    this.getFormControlByIndex(index).legalRepresentativePepName.setErrors(null);
    this.getFormControlByIndex(index).legalRepresentativePepRole.setErrors(null);
    this.getFormControlByIndex(index).legalRepresentativePoliticalExposition.setErrors(null);
    this.getFormControlByIndex(index).legalRepresentativeRne.setErrors(null);
    this.getFormControlByIndex(index).legalRepresentativeRneEnabled.setErrors(null);
  }

  private renderPlusIcon(): SafeHtml {
    const iconDefinition = icon({ prefix: 'fas', iconName: 'plus-circle' });
    return iconDefinition.html;
  }

  private renderCloseIcon(): SafeHtml {
    const iconDefinition = icon({ prefix: 'fas', iconName: 'times' });
    return iconDefinition.html;
  }

  private createPartnerFormsInternal(partnerData: Partial<PartnerDataModel>): void {
    this.fieldsArray.push(this.createFields());
    this.setDocumentCategoryContext(this.getIndex(), this.documentCategoryContextDefault);
    this.partnerFormsArray.push(this.createPartnerForm(partnerData));
  }

  private loadDocumentCategoryContext(index: number, partnerData: Partial<PartnerDataModel>): void {
    const documentCategoryContext = partnerData?.documentCategory ? partnerData.documentCategory : this.documentCategoryContextDefault;

    this.setDocumentCategoryContext(index, documentCategoryContext);
  }

  private removeAllValidationWhenHasData(index: number, partnerData: Partial<PartnerDataModel>): void {
    this.removeCnpjValidationWhenIsCpfAndHasData(index, partnerData);
    this.removeCpfValidationWhenIsCnpjAndHasData(index, partnerData);
  }

  private removeCnpjValidationWhenIsCpfAndHasData(index: number, partnerData: Partial<PartnerDataModel>): void {
    if (partnerData?.documentCategory === DocumentCategory.CPF) {
      this.removeAllCnpjFieldAndEnableCpf(index);
    }
  }

  private removeCpfValidationWhenIsCnpjAndHasData(index: number, partnerData: Partial<PartnerDataModel>): void {
    if (partnerData?.documentCategory === DocumentCategory.CNPJ) {
      this.removeAllCpfFieldAndEnableCnpj(index);
    }
  }

  private setDocumentCategoryWhenHasData(partnerData: Partial<PartnerDataModel>): void {
    this.documentCategory = partnerData?.documentCategory;
  }

  private addPartnerFormInternal(partnerData: Partial<PartnerDataModel> = {}): void {
    const totalAmount = this.getTotalAmountOfPorcent();
    const maxPorcentValid = 100;

    if (totalAmount > maxPorcentValid) {
      this.dialogService.openDialog(Messages.MAX_PORCENT_LIMIT_REACHED);
      return;
    }

    this.createPartnerFormsInternal(partnerData);
    this.loadDocumentCategoryContext(this.getIndex(), partnerData);
    this.setEnableFields(partnerData);
    this.removeAllValidationWhenHasData(this.getIndex(), partnerData);
    this.setDocumentCategoryWhenHasData(partnerData);
  }

  private setEnableFieldData(isEnable: boolean, formName: string): void {
    const field = this.fieldsArray[this.getIndex()]?.[formName] as FdSelectConfig;
    if (field) {
      field.disabled = !isEnable;
    }
  }

  private setEnableFields(partnerData: any) {
    this.setEnableFieldData(partnerData?.politicalExposition, 'politicalExposition');
    this.setEnableFieldData(partnerData?.rneEnabled, 'rneEnabled');
    this.setEnableFieldData(partnerData?.legalRepresentative?.rneEnabled, 'legalRepresentativeRneEnabled');
    this.setEnableFieldData(partnerData?.legalRepresentative?.politicalExposition, 'legalRepresentativePoliticalExposition');
    if (this.isEmptyObject(partnerData)) {
      this.setEnableFieldData(true, 'legalRepresentativeEnable');
    } else {
      this.setEnableFieldData(!!partnerData?.legalRepresentative?.enabled, 'legalRepresentativeEnable');
    }
  }

  private isEmptyObject(obj: any) {
    return JSON.stringify(obj) === '{}';
  }

  private createPartnerForm(partnerData: Partial<PartnerDataModel>): FormGroup {
    this.showLegalRepresentativeFields = partnerData?.isLegalRepresentativeOptions ? partnerData?.isLegalRepresentativeOptions : false;
    return this.formBuilder.group({
      cpfCnpj: [partnerData.cpfCnpj, [Validators.required, CpfCnpjValidator]],
      name: [partnerData.name, [Validators.required, NameSurnameValidator]],
      motherName: [partnerData.motherName, [RequiredIfValidator(() => this.motherDataEnabled), NameSurnameValidator]],
      birthDate: [partnerData.birthDate, [Validators.required, dateValidator()]],
      companyParticipation: [partnerData.companyParticipation, [Validators.required, PercentValidator(0, 100, false)]],
      politicalExposition: [partnerData.politicalExposition, Validators.required],
      nationality: [partnerData.nationality, [Validators.required]],
      rne: [partnerData.rne, [RequiredIfValidator(() => partnerData?.rneEnabled)]],
      rneEnabled: [partnerData.rneEnabled],
      pepName: [partnerData.politicallyExposedPerson ? partnerData.politicallyExposedPerson.name : ''],
      pepRole: [partnerData.politicallyExposedPerson ? partnerData.politicallyExposedPerson.responsibility : ''],
      pepGovernmentOrganization: [partnerData.politicallyExposedPerson ? partnerData.politicallyExposedPerson.governmentAgency : ''],
      socialReason: [partnerData.socialReason, [Validators.required]],
      openDate: [partnerData.openDate, [Validators.required, dateValidator()]],
      isLegalRepresentativeOptions: [partnerData.isLegalRepresentativeOptions ? partnerData.isLegalRepresentativeOptions : false],
      documentCategory: [partnerData?.documentCategory],
      legalRepresentativeEnable: [partnerData?.legalRepresentative?.enabled],
      legalRepresentativeRneEnabled: [partnerData.legalRepresentative?.rneEnabled],
      legalRepresentativeRne: [
        partnerData.legalRepresentative?.rne,
        [RequiredIfValidator(() => partnerData?.legalRepresentative?.rneEnabled)],
      ],
      legalRepresentativeCpf: [
        partnerData.legalRepresentative?.cpf,
        [RequiredIfValidator(() => partnerData?.legalRepresentative?.enabled), CpfValidator],
      ],
      legalRepresentativeName: [
        partnerData.legalRepresentative?.name,
        [RequiredIfValidator(() => partnerData?.legalRepresentative?.enabled), NameSurnameValidator],
      ],
      legalRepresentativeBirthDate: [
        partnerData.legalRepresentative?.birthDate,
        [RequiredIfValidator(() => partnerData?.legalRepresentative?.enabled), dateValidator()],
      ],
      legalRepresentativeNationality: [
        partnerData.legalRepresentative?.nationality,
        [RequiredIfValidator(() => partnerData?.legalRepresentative?.enabled)],
      ],
      legalRepresentativePoliticalExposition: [
        partnerData.legalRepresentative?.politicalExposition,
        [RequiredIfValidator(() => partnerData?.legalRepresentative?.enabled)],
      ],
      legalRepresentativePepName: [
        partnerData.legalRepresentative?.politicallyExposedPerson ? partnerData.legalRepresentative?.politicallyExposedPerson?.name : '',
        [RequiredIfValidator(() => partnerData?.legalRepresentative?.politicalExposition)],
      ],
      legalRepresentativePepRole: [
        partnerData.legalRepresentative?.politicallyExposedPerson
          ? partnerData.legalRepresentative?.politicallyExposedPerson?.responsibility
          : '',
        [RequiredIfValidator(() => partnerData?.legalRepresentative?.politicalExposition)],
      ],
      legalRepresentativePepGovernmentOrganization: [
        partnerData.legalRepresentative?.politicallyExposedPerson
          ? partnerData.legalRepresentative?.politicallyExposedPerson?.governmentAgency
          : '',
        [RequiredIfValidator(() => partnerData?.legalRepresentative?.politicalExposition)],
      ],
    });
  }

  private removeCpfCnpjMask(document: string): string {
    return document?.replace(/\D/g, '');
  }

  private removeDateMask(date: string): string {
    return date.replace(/[-/\ ]/g, '');
  }

  private isCNPJ(document: string): boolean {
    return CNPJ_REGEX.test(this.removeCpfCnpjMask(document));
  }

  private isCPF(document: string): boolean {
    return CPF_REGEX.test(this.removeCpfCnpjMask(document));
  }

  private isCpfOrCnpj(document: string): boolean {
    return this.isCPF(document) || this.isCNPJ(document);
  }

  private getCpfCnpjForm(index: number): AbstractControl {
    const formGroup = this.getFormControlByIndex(index);
    return formGroup.cpfCnpj;
  }

  private setDocumentCategoryContext(index: number, documentCategory: string): void {
    const field = this.fieldsArray[index]?.documentCategory as FdSelectConfig;
    if (field) {
      field.context = documentCategory;
    }
  }

  private getDocumentCategoryContext(index: number): string {
    return (this.fieldsArray[index]?.documentCategory as FdSelectConfig).context;
  }

  private removeAllCpfFieldAndEnableCnpj(index: number): void {
    const control = this.getFormControlByIndex(index);
    this.disableCpfField(control);
    this.enableAllCnpjField(control);
  }

  private removeAllCnpjFieldAndEnableCpf(index: number): void {
    const control = this.getFormControlByIndex(index);
    this.disableCnpjField(control);
    this.enableAllCpfField(control);
  }

  private enableAllCnpjField(control: any): void {
    control.legalRepresentativeCpf.enable();
    control.legalRepresentativeName.enable();
    control.legalRepresentativeBirthDate.enable();
    control.socialReason.enable();
    control.openDate.enable();
  }

  private enableAllCpfField(control: any): void {
    control.name.enable();
    control.birthDate.enable();
    control.legalRepresentativeCpf.enable();
    control.legalRepresentativeName.enable();
    control.legalRepresentativeBirthDate.enable();
    control.rne.enable();
    control.nationality.enable();
    control.rneEnabled.enable();
    control.rneEnabled.setValue(false);
  }

  private disableCpfField(control: any) {
    control.name.setValue('');
    control.birthDate.setValue('');
    control.name.disable();
    control.birthDate.disable();
    control.rne.disable();
    control.nationality.disable();
    control.rneEnabled.disable();
  }

  private disableCnpjField(control: any) {
    control.socialReason.setValue('');
    control.openDate.setValue('');
    control.socialReason.disable();
    control.openDate.disable();
  }

  private setValuesToEmptyWhenContextIsCpf(index: number): void {
    const context = this.getDocumentCategoryContext(index);
    const control = this.getFormControlByIndex(index);

    if (context === DocumentCategory.CPF) {
      control.nationality.setValue('');
      control.rne.setValue('');
      control.companyParticipation.setValue('');
      control.pepName.setValue('');
      control.pepRole.setValue('');
      control.pepGovernmentOrganization.setValue('');
      control.politicalExposition.setValue(false);
      this.setEnableFieldData(false, 'politicalExposition');

      control.nationality.setErrors(null);
      control.rne.setErrors(null);
      control.companyParticipation.setErrors(null);
      control.pepName.setErrors(null);
      control.pepRole.setErrors(null);
      control.pepGovernmentOrganization.setErrors(null);
      control.politicalExposition.setErrors(null);

      this.removeLegalRepresentativeValidators(index);
      control.legalRepresentativeEnable.setValue(false);
      control.isLegalRepresentativeOptions.setValue(false);
      this.showLegalRepresentativeFields = false;
      this.setEnableFieldData(false, 'legalRepresentativeRneEnabled');
      this.setEnableFieldData(false, 'legalRepresentativePoliticalExposition');
    }
  }

  private setValuesToEmptyWhenContextIsCnpj(index: number): void {
    const context = this.getDocumentCategoryContext(index);
    const control = this.getFormControlByIndex(index);

    if (context === DocumentCategory.CNPJ) {
      control.nationality.setValue('');
      control.rne.setValue('');
      control.companyParticipation.setValue('');
      control.pepName.setValue('');
      control.pepRole.setValue('');
      control.pepGovernmentOrganization.setValue('');
      control.politicalExposition.setValue(false);
      this.setEnableFieldData(false, 'politicalExposition');

      control.nationality.setErrors(null);
      control.rne.setErrors(null);
      control.companyParticipation.setErrors(null);
      control.pepName.setErrors(null);
      control.pepRole.setErrors(null);
      control.pepGovernmentOrganization.setErrors(null);
      control.politicalExposition.setErrors(null);

      this.removeLegalRepresentativeValidators(index);
      control.legalRepresentativeEnable.setValue(false);
      control.isLegalRepresentativeOptions.setValue(false);
      this.showLegalRepresentativeFields = false;
      this.setEnableFieldData(false, 'legalRepresentativeRneEnabled');
      this.setEnableFieldData(false, 'legalRepresentativePoliticalExposition');
    }
  }

  private processAllValidationForCnpj(index: number): void {
    this.setValuesToEmptyWhenContextIsCpf(index);
    this.documentCategory = DocumentCategory.CNPJ;
    this.setDocumentCategoryContext(index, DocumentCategory.CNPJ);
    this.removeAllCpfFieldAndEnableCnpj(index);
  }

  private processAllValidationForCpf(index: number): void {
    this.setValuesToEmptyWhenContextIsCnpj(index);
    this.documentCategory = DocumentCategory.CPF;
    this.setDocumentCategoryContext(index, DocumentCategory.CPF);
    this.removeAllCnpjFieldAndEnableCpf(index);
  }

  private persistDataForCpf(partner: any, documentCategoryContext: string) {
    if (documentCategoryContext !== DocumentCategory.CPF) {
      return;
    }

    if (this.hasPartnerDataForCpf(partner)) {
      partner.loadedPartners = this.loadedPartners;
      partner.birthDate = this.removeDateMask(partner.birthDate);
      partner.documentCategory = DocumentCategory.CPF;
      partner.rneEnabled = partner?.rne ? true : partner.rneEnabled;
      this.persistLegalRepresentative(partner);
      this.persistPoliticalExposition(partner);
    }
  }

  private persistDataForCnpj(partner: any, documentCategoryContext: string) {
    if (documentCategoryContext !== DocumentCategory.CNPJ) {
      return;
    }

    if (this.hasPartnerDataForCnpj(partner)) {
      partner.loadedPartners = this.loadedPartners;
      partner.openDate = this.removeDateMask(partner.openDate);
      partner.documentCategory = DocumentCategory.CNPJ;
      (partner.rneEnabled = this.validatedRneLegalRepresentativeEnabled(partner.nationality)), this.persistLegalRepresentative(partner);
      this.persistPoliticalExposition(partner);
    }
  }

  private persistPoliticalExposition(partner: any) {
    if (this.hasPartnerPoliticalExposition(partner)) {
      partner.politicallyExposedPerson = {
        name: partner.pepName,
        responsibility: partner.pepRole,
        governmentAgency: partner.pepGovernmentOrganization,
      };
    }
  }

  private persistLegalRepresentative(partner: any) {
    if (this.hasLegalRepresentative(partner)) {
      partner.legalRepresentative = {
        enabled: this.hasLegalRepresentative(partner) ? true : !!partner.legalRepresentativeEnable,
        cpf: partner.legalRepresentativeCpf,
        name: partner.legalRepresentativeName,
        birthDate: this.removeDateMask(partner.legalRepresentativeBirthDate),
        nationality: partner.legalRepresentativeNationality,
        rne: partner.legalRepresentativeRne,
        rneEnabled: this.validatedRneLegalRepresentativeEnabled(partner.legalRepresentativeNationality),
        politicalExposition: partner.legalRepresentativePoliticalExposition,
        politicallyExposedPerson: {
          name: partner.legalRepresentativePepName,
          responsibility: partner.legalRepresentativePepRole,
          governmentAgency: partner.legalRepresentativePepGovernmentOrganization,
        },
      };
    }
  }

  private validatedRneLegalRepresentativeEnabled(nationality: string) {
    return !this.isEmpty(nationality) && nationality !== 'Brasileiro';
  }

  private isEmpty(str) {
    return !str || str.length === 0;
  }

  private createPartnerValidationModel(formGroupList: FormGroup[] | AbstractControl[]): SerproV2PartnerValidationModel {
    const partnerValidationModel = new SerproV2PartnerValidationModel();

    formGroupList.forEach((formGroup, index) => {
      if (formGroup instanceof FormGroup) {
        const control = formGroup.controls;

        const partnerValidationDTO = new PartnerV2ValidationDTO();
        const legalRepresentative = new LegalRepresentativeDTO();

        partnerValidationDTO.isPep = control.politicalExposition.value;
        partnerValidationDTO.porcentagemParticipacao = control.companyParticipation.value;

        this.createPartnerCPFModel(partnerValidationDTO, legalRepresentative, control, index);
        this.createPartnerCNPJModel(partnerValidationDTO, legalRepresentative, control, index);

        partnerValidationModel.cnpj = this.proposal?.registrationDataPersonalInfo?.cpfCnpj;
        partnerValidationModel.socios.push(partnerValidationDTO);
      }
    });

    return partnerValidationModel;
  }

  private createPartnerCPFModel(
    partnerDTO: PartnerV2ValidationDTO,
    legalRepresentative: LegalRepresentativeDTO,
    control: any,
    index: number
  ): void {
    if (this.getDocumentCategoryContext(index) === DocumentCategory.CPF) {
      partnerDTO.cpf = control.cpfCnpj.value;
      partnerDTO.nome = control.name.value;
      partnerDTO.dataNascimento = control.birthDate.value;
      legalRepresentative.nome = control.legalRepresentativeName.value;
      legalRepresentative.cpf = control.legalRepresentativeCpf.value;
      partnerDTO.representanteLegal = legalRepresentative;
    }
  }

  private createPartnerCNPJModel(
    partnerDTO: PartnerV2ValidationDTO,
    legalRepresentative: LegalRepresentativeDTO,
    control: any,
    index: number
  ): void {
    if (this.getDocumentCategoryContext(index) === DocumentCategory.CNPJ) {
      partnerDTO.nome = control.socialReason.value;
      partnerDTO.cnpj = control.cpfCnpj.value;
      partnerDTO.dataFundacao = control.openDate.value;
      legalRepresentative.nome = control.legalRepresentativeName.value;
      legalRepresentative.cpf = control.legalRepresentativeCpf.value;
      partnerDTO.representanteLegal = legalRepresentative;
    }
  }

  private createPartnerDataModelForCPF(partnerResponseSerpro: PartnerSerproModel): void {
    if (this.isCPF(partnerResponseSerpro.cpfCnpj)) {
      const partnerDataCpf = new PartnerDataModel();

      partnerDataCpf.documentCategory = DocumentCategory.CPF;
      this.documentCategory = DocumentCategory.CPF;

      partnerDataCpf.cpfCnpj = partnerResponseSerpro.cpfCnpj;
      partnerDataCpf.name = partnerResponseSerpro.nome;
      partnerDataCpf.politicalExposition = partnerResponseSerpro.pep;
      partnerDataCpf.nationality = partnerResponseSerpro.pais?.descricao === 'BRASIL' ? 'Brasileiro' : '';
      this.createLegalRepresentative(partnerResponseSerpro.representanteLegal, partnerDataCpf);
      this.addPartnerFormInternal(partnerDataCpf);
    }
  }

  private createPartnerDataModelForCNPJ(partnerResponseSerpro: PartnerSerproModel): void {
    if (this.isCNPJ(partnerResponseSerpro.cpfCnpj)) {
      const partnerDataCnpj = new PartnerDataModel();

      partnerDataCnpj.documentCategory = DocumentCategory.CNPJ;
      this.documentCategory = DocumentCategory.CNPJ;

      partnerDataCnpj.cpfCnpj = partnerResponseSerpro.cpfCnpj;
      partnerDataCnpj.socialReason = partnerResponseSerpro.nome;
      partnerDataCnpj.politicalExposition = partnerResponseSerpro.pep;
      this.createLegalRepresentative(partnerResponseSerpro.representanteLegal, partnerDataCnpj);
      this.addPartnerFormInternal(partnerDataCnpj);
    }
  }

  private createLegalRepresentative(legalRepresentative: LegalRepresentativeDTO, partnerData: PartnerDataModel) {
    partnerData.legalRepresentative = new LegalRepresentative();
    const invalidCpf = '00000000000';

    if (legalRepresentative?.cpf === null || legalRepresentative.cpf === invalidCpf) {
      partnerData.legalRepresentative.enabled = false;
      return;
    }

    partnerData.legalRepresentative.enabled = true;
    partnerData.legalRepresentative.name = legalRepresentative.nome;
    partnerData.legalRepresentative.cpf = legalRepresentative.cpf;
  }

  private hasPartnerPoliticalExposition(partner: any) {
    return partner.politicalExposition && partner.pepName && partner.pepRole && partner.pepGovernmentOrganization;
  }

  private hasPartnerDataForCpf(partner: any) {
    return partner?.cpfCnpj && partner?.name && partner?.birthDate;
  }

  private hasPartnerDataForCnpj(partner: any) {
    return partner?.cpfCnpj && partner?.socialReason && partner?.openDate;
  }

  private hasLegalRepresentative(partner: any) {
    return partner?.legalRepresentativeCpf && partner?.legalRepresentativeName && partner?.legalRepresentativeBirthDate;
  }
}
